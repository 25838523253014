import "iframe-resizer";
import { HelmetProvider } from "@dogstrust/dt-web-core-lib-front-end/src/Helmet";
import { CharityShopsProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/CharityShops/CharityShops.provider";
import { CmsMapProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/CmsMap/CmsMap.provider";
import { DogSchoolApiProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/DogSchoolApi/DogSchoolApi.provider";
import { DogSearchApiProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/DogSearchApi/uk/DogSearchApi.provider";
import FavouritesManagerProvider from "@dogstrust/dt-web-core-lib-front-end/src/context/FavouritesManager/FavouritesManager.provider";
import { MyAccountProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/MyAccount/uk/MyAccount.provider";
import { OurCentresProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/OurCentres/OurCentres.provider";
import { PageManagerProvider } from "@dogstrust/dt-web-core-lib-front-end/src/context/PageManager/PageManager.provider";
import SponsorDogsProvider from "@dogstrust/dt-web-core-lib-front-end/src/context/SponsorDogs/SponsorDogs.provider";
import VolunteerVacanciesProvider from "@dogstrust/dt-web-core-lib-front-end/src/context/VolunteerVacancies/VolunteerVacancies.provider";
import React from "react";
import type { ReactNode } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "@dogstrust/dt-web-core-lib-front-end/src/theme/aerBase.scss";
import { NationalDogSurveyContextProvider } from "@dogstrust/dt-web-core-lib-front-end/src/NationalDogSurvey/NationalDogSurvery.context";
import { Fragments } from "@dogstrust/uk/gql";
interface Props {
	element: ReactNode;
}

const RootElement: React.FC<Props> = ({ element }) => {
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
			useRecaptchaNet={true}
			useEnterprise={true}
			scriptProps={{
				async: false,
				defer: false,
				appendTo: "head",
			}}
		>
			<Fragments />
			<HelmetProvider>
				<PageManagerProvider>
					<MyAccountProvider>
						<OurCentresProvider>
							<CharityShopsProvider>
								<CmsMapProvider>
									<DogSchoolApiProvider>
										<SponsorDogsProvider>
											<VolunteerVacanciesProvider>
												<DogSearchApiProvider>
													<FavouritesManagerProvider>
														<NationalDogSurveyContextProvider>
															{element}
														</NationalDogSurveyContextProvider>
													</FavouritesManagerProvider>
												</DogSearchApiProvider>
											</VolunteerVacanciesProvider>
										</SponsorDogsProvider>
									</DogSchoolApiProvider>
								</CmsMapProvider>
							</CharityShopsProvider>
						</OurCentresProvider>
					</MyAccountProvider>
				</PageManagerProvider>
			</HelmetProvider>
		</GoogleReCaptchaProvider>
	);
};

export const wrapRootElement = RootElement;
